
import Paging from "@/components/Paging.vue";
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
@Component({
  name: "DeleteTraining",
  props: {},
  components: {Paging,Breadcrumbs},
})

export default class DeleteTraining extends Vue {
  zzlApi: any;
  tableData: any = [];
  years: any = [
   
  ];
  idArr: any = [];
  conditions: any = {
    year: new Date().getFullYear(),
    reportName: "",
    pageNum: 1,
    pageSize: 20,
    total:0,
    // createdTime: [],
  };
  // 审核状态处理
  getStatus(status: any) {
    switch (status) {
      case 1:
        return "暂存";
      case 10:
        return "待审核"
      case 5:
        return "被驳回";
      case 15:
        return "已通过";
      default:
        return "";
    }
  };
  selectionChange(selectItems: any) {  // 批量选择每一行的id
    this.idArr = selectItems.map((m: any) => {
      return m.id;
    });
  }
  resetAction() {
    this.conditions = {
      pageNum: 1,
      total:0,
      pageSize: 20,
      year: new Date().getFullYear(),
      reportName: "",
    };
  }
  searchAction() {
    this.conditions.pageNum=1;
    this.getList();
  }
  async deleteAction(row: any) { // 批量删除及单个删除
    let ids: any = [];
        if (!row) {
          if(!this.idArr.length){
            this.$message.warning(`请至少选择一条数据`);
            return;
          }
          ids = [...this.idArr];
        }else{
          ids =[row.id];
        }
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        const res = await this.zzlApi.coursereport.Delete({ ids }, null);
        if (res.code == 0) {
          this.getList();
          this.$message.success("删除成功");
        } else {
          this.$message.error(`删除失败：${res.msg}`);
        }
      })
      .catch(() => {
        this.$message.info("已取消");
      });
  }
  /* 查询课程列表 */
  async getList() {
    const res = await this.zzlApi.coursereport.Querylist(
      { ...this.conditions },
      null
    );
    if (res.code === 0) {
      this.tableData = res.data;

      this.conditions.total = Number(res.data.count);
    //   for (let i in this.tableData) {
    //     this.tableData[i].progress =
    //       parseInt(this.tableData[i].progress) > 100
    //         ? "100%"
    //         : this.tableData[i].progress;
    //   }
    //   if (res.data.statis) {
    //     this.tableData.unshift(res.data.statis[0]);
    //   }
    } else {
      this.$message.error(res.msg.msg);
    }
  }
  /* 获得年份 */
  async getYear() {
    const res = await this.zzlApi.coursereport.GetFillingYear();
    if (res.code === 0) {
      for (const item of res.data) {
        this.years.push({
          label: item + "年",
          value: item,
        });
      }
    }
  }
  activated() {
  }
  mounted() {
    this.getYear();
    this.getList();
  }
}
